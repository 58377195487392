import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeaderComponent from "../components/page-header";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: PAGE NOT FOUND" />
      <PageHeaderComponent name="404: Not Found" desc="You just hit a route that doesn&#39;t exist... the sadness."/>

  </Layout>
)

export default NotFoundPage
